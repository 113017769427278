// react
import { useMaterialUIController } from "context";
import { useQueries } from "@tanstack/react-query";
import { useState, useEffect } from "react";

import http from "services/http";

import MDLoader from "components/MDLoader";

// react router
import { useParams } from "react-router-dom";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCardMarketDataCheck from "examples/Cards/StatisticsCards/ComplexStatisticsCardMarketDataCheck";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTableWithColumnFilters from "components/Tables/DataTableWithColumnFilters";

// Data
import checkSocratesDataDetailTableData from "layouts/tables/data/checkSocratesDataDetailTableData";
import MDDatePickerMDY from "components/MDDatePickerMDY";
import { formatDateInNewYorkMMDDYYYY } from "utils/dateUtil";

function CheckSocratesDataDetails() {
  const { PEITicker, FilterType, TimeFrame } = useParams();
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [dataFilteredByDate, setDataFilteredByDate] = useState(null);

  const handleChangeFrom = (e, value) => {
    setFrom(value);
  };
  const handleChangeTo = (e, value) => {
    setTo(value);
  };

  const getCheckSocratesDataDetails = async () => {
    const result = await http({
      path: `/checksocratesdata/detail/${PEITicker}/${FilterType}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const filterDescription = (filterType) => {
    let description;
    switch (filterType) {
      case "":
        description = "";
        break;
      case "undefined":
        description = "";
        break;
      case "1":
        description = "% Increase More Than 500";
        break;
      case "2":
        description = "% Increase More Than 100";
        break;
      case "3":
        description = "% Increase More Than 50";
        break;
      case "4":
        description = "% Decrease Less Than -50";
        break;
      case "5":
        description = "% Decrease Less Than -100";
        break;
      case "6":
        description = "% Decrease Less Than -500";
        break;
      default:
        description = "";
        break;
    }
    return description;
  };

  const filterDataByFilterType = (array) => {
    let filteredArray;
    switch (FilterType) {
      case "":
        filteredArray = array;
        break;
      case "undefined":
        filteredArray = array;
        break;
      case "1":
        filteredArray = array.filter(
          (el) => el && Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) >= 500
        );
        break;
      case "2":
        filteredArray = array.filter(
          (el) =>
            el &&
            el.Result &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) >= 100 &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) < 500
        );
        break;
      case "3":
        filteredArray = array.filter(
          (el) =>
            el &&
            el.Result &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) >= 50 &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) < 100
        );
        break;
      case "4":
        filteredArray = array.filter(
          (el) =>
            el &&
            el.Result &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) >= -100 &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) <= -50
        );
        break;
      case "5":
        filteredArray = array.filter(
          (el) =>
            el &&
            el.Result &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) >= -500 &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) < -100
        );
        break;
      case "6":
        filteredArray = array.filter(
          (el) =>
            el &&
            el.Result &&
            Math.max(el.Result1, el.Result2, el.Result3, el.Result4, el.Result5) < -500
        );
        break;
      default:
        filteredArray = "";
        break;
    }
    return filteredArray;
  };

  let infoGlobal;

  const [fetchedCheckSocratesDataDetails] = useQueries({
    queries: [
      {
        queryKey: ["fetchedCheckSocratesDataDetails"],
        queryFn: getCheckSocratesDataDetails,
        select: (data) => {
          const foundObject = data.find((obj) => obj.TimeFrame === parseInt(TimeFrame, 10));
          infoGlobal = foundObject;
          if (FilterType === "0") {
            return foundObject.Data;
          }
          const dataFiltered = filterDataByFilterType(foundObject.CalculatedField);
          return dataFiltered.map((el) => ({ ...el.DataElem }));
        },
      },
    ],
  });

  const { columns } = checkSocratesDataDetailTableData();

  useEffect(() => {
    if (from !== "" && to !== "") {
      const temp = fetchedCheckSocratesDataDetails.data.filter(
        (el) =>
          el.Date && el && new Date(el.Date) >= new Date(from) && new Date(el.Date) <= new Date(to)
      );
      setDataFilteredByDate(temp);
    } else {
      setDataFilteredByDate(null);
    }
  }, [from, to]);

  if (fetchedCheckSocratesDataDetails.isLoading) return <MDLoader />;
  if (fetchedCheckSocratesDataDetails.isError)
    return <div>Error at loading checksocratesdatadetail</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={1}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={3} lg={3}>
            <MDBox mb={1.5}>
              {fetchedCheckSocratesDataDetails && fetchedCheckSocratesDataDetails.data.length > 0 && (
                <ComplexStatisticsCardMarketDataCheck
                  color="warning"
                  icon="api"
                  title=""
                  count={PEITicker}
                  details={infoGlobal.Name}
                  moreDetails={`Reported Date: ${formatDateInNewYorkMMDDYYYY(
                    infoGlobal.InsertedDate
                  )}`}
                  percentage={{
                    color: "success",
                    // amount: "+0%",
                    label: PEITicker,
                  }}
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={6} md={5} lg={5}>
            <MDBox mb={1.5}>
              {FilterType &&
                fetchedCheckSocratesDataDetails &&
                fetchedCheckSocratesDataDetails.data.length > 0 && (
                  <Card sx={{ height: "25%" }}>
                    <MDBox pt={1} px={3}>
                      <MDTypography variant="h6" fontWeight="medium">
                        FILTER APPLIED
                      </MDTypography>
                      <MDBox mt={0} mb={0}>
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {filterDescription(FilterType)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox pt={0} px={3}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Range Date
                      </MDTypography>
                      <MDBox mt={0} mb={1}>
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            From:
                          </MDTypography>
                          <MDDatePickerMDY
                            input={{ placeholder: "Select a date", size: "small" }}
                            value={from}
                            onChange={handleChangeFrom}
                          />
                        </MDTypography>
                      </MDBox>
                      <MDBox mt={0} mb={2}>
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            To:
                          </MDTypography>
                          <MDDatePickerMDY
                            input={{ placeholder: "Select a date", size: "small" }}
                            value={to}
                            onChange={handleChangeTo}
                          />
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Card>
                )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox my={3} mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Data Integrity Check
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {fetchedCheckSocratesDataDetails?.data ? (
                  <DataTableWithColumnFilters
                    table={{
                      columns,
                      rows: dataFilteredByDate || fetchedCheckSocratesDataDetails.data,
                    }}
                    showTotalEntries
                    isSorted
                    noEndBorder
                    entriesPerPage
                    canSearch
                    arrayColumnsToSkipSorted={["Count", "Date"]}
                  />
                ) : (
                  <p>No data available for this market</p>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default CheckSocratesDataDetails;
