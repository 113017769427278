/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Markets from "layouts/markets";
import Gmwcomments from "layouts/gmwcomments";
import FailedMarkets from "layouts/failedmarkets";
import FailedMarketsZeroValue from "layouts/failedmarketszerovalue";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SignOut from "layouts/authentication/sign-out";
import AddUser from "layouts/adduser";
import SnapshotsOverview from "layouts/snapshotsoverview";
import SubscriptionsOverview from "layouts/subscriptionsoverview";
import Analysis from "layouts/analysis";
import MarketSubscription from "layouts/marketsubscription";
import MonthlyAnalysis from "layouts/monthlyanalysis";
import DataDownloadCheck from "layouts/datadownloadcheck";
import MarketDataCheck from "layouts/marketdatacheck";
import MarketsManagement from "layouts/marketsmanagement";
import DeactivateMarkets from "layouts/deactivatemarkets";
import TradingVolume from "layouts/tradingvolume";
import MissingMarketAnalysisData from "layouts/missingmarketanalysisdata";
import NewMarketRequest from "layouts/newmarketrequest";
import ViewAllTagsII from "layouts/viewalltagsII";
import TagVolumeReport from "layouts/tagvolumereport";
import NewTag from "layouts/newtag";
import UpdateMarketDataStore from "layouts/updatemarketdatastore";
import UpdateMarketPriceData from "layouts/updatemarketpricedata";
import Reversal from "layouts/reversal";
import DataArray from "layouts/dataarray";
import PercentageChange from "layouts/percentagechange";
import BullishBearish from "layouts/bullishbearish";
import TopMoversCheck from "layouts/topmoverscheck";
import MissingModels from "layouts/missingmodels";

const routes = [
  {
    type: "collapse",
    noCollapse: true,
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Markets",
    key: "marketsMenu",
    icon: <Icon fontSize="small">api</Icon>,
    collapse: [
      {
        name: "Markets Info",
        key: "markets",
        route: "/markets",
        component: <Markets />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Markets Management",
    key: "markets-management",
    icon: <Icon fontSize="small">store</Icon>,
    collapse: [
      {
        name: "New Market Request",
        key: "new-market-request",
        route: "/new-market-request",
        component: <NewMarketRequest />,
      },
      {
        name: "View All Request",
        key: "view-all-request",
        route: "/view-all-request",
        component: <MarketsManagement />,
      },
      {
        name: "Deactivate Markets",
        key: "deactivate-markets",
        route: "/deactivate-markets",
        component: <DeactivateMarkets />,
      },
      {
        name: "Find Trading Volume",
        key: "find-trading-volume",
        route: "/find-trading-volume",
        component: <TradingVolume />,
      },
      {
        name: "Manage Tags",
        key: "manage-tags",
        route: "/manage-tags",
        component: <NewTag />,
      },
      {
        name: "View All Tags",
        key: "view-all-tags",
        route: "/view-all-tags",
        component: <ViewAllTagsII />,
      },
      {
        name: "Tag Volume Report",
        key: "tag-volume-report",
        route: "/tag-volume-report",
        component: <TagVolumeReport />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Failed Markets",
    key: "failedMarketsMenu",
    icon: <Icon fontSize="small">api</Icon>,
    collapse: [
      {
        // type: "collapse",
        // noCollapse: true,
        name: "Failed Markets By Update Date",
        key: "failed-markets",
        // icon: <Icon fontSize="small">table_view</Icon>,
        route: "/failed-markets/timeframe/:timeframeId",
        routeSidenav: "/failed-markets/timeframe/daily",
        component: <FailedMarkets />,
      },

      {
        name: "Failed Markets By Zero Value",
        key: "failed-markets-zero-value",
        route: "/failed-markets-zero-value",
        component: <FailedMarketsZeroValue />,
      },
    ],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Market Subscription Summary",
    key: "market-subscription-summary",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/market-subscription-summary",
    component: <MarketSubscription />,
  },
  {
    type: "collapse",
    name: "Product Usage",
    key: "productUsage",
    icon: <Icon fontSize="small">analytics</Icon>,
    collapse: [
      {
        name: "Subscription Overview",
        key: "subscription-overview",
        route: "/subscription-overview",
        component: <SubscriptionsOverview />,
      },
      {
        name: "Snapshots Overview",
        key: "snapshots-overview",
        route: "/snapshots-overview",
        component: <SnapshotsOverview />,
      },
      {
        name: "Analysis",
        key: "analysis",
        route: "/analysis",
        component: <Analysis />,
      },
      {
        name: "Monthly Analysis",
        key: "monthly-analysis",
        route: "/monthly-analysis",
        component: <MonthlyAnalysis />,
      },
    ],
  },
  // {
  // type: "collapse",
  // noCollapse: true,
  // name: "Data Processes",
  // key: "processes",
  // icon: <Icon fontSize="small">notifications</Icon>,
  // route: "/processes",
  // component: <Processes />,
  // },
  {
    type: "collapse",
    name: "Data Processes",
    key: "processes",
    icon: <Icon fontSize="small">notifications</Icon>,
    collapse: [
      {
        name: "Data Download Check",
        key: "data-download-check",
        route: "/data-download-check",
        component: <DataDownloadCheck />,
      },
      {
        name: "Market Data Check",
        key: "market-data-check",
        route: "/market-data-check",
        component: <MarketDataCheck />,
      },
      {
        name: "Market Price Data Check",
        key: "market-price-data-check",
        route: "/market-price-data-check",
        component: <PercentageChange />,
      },
      {
        name: "Update Market Data Store",
        key: "update-market-data-store",
        route: "/update-market-data-store",
        component: <UpdateMarketDataStore />,
      },
      {
        name: "Update Market Price Data",
        key: "update-market-price-data",
        route: "/update-market-price-data",
        component: <UpdateMarketPriceData />,
      },
      {
        name: "Missing Market Analysis",
        key: "missing-market-analysis",
        route: "/missing-market-analysis",
        component: <MissingMarketAnalysisData />,
      },
      {
        name: "Top Movers Check",
        key: "top-movers-check",
        route: "/top-movers-check",
        component: <TopMoversCheck />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Model Updates Summary",
    key: "model-updates-summary",
    icon: <Icon fontSize="small">notifications</Icon>,
    collapse: [
      {
        name: "GMW Comments",
        key: "gmw-comments-summary",
        route: "/gmw-comments-summary",
        component: <Gmwcomments />,
      },
      {
        name: "Missing Models",
        key: "missing-models",
        route: "/missing-models",
        component: <MissingModels />,
      },
      {
        name: "Reversal",
        key: "reversal",
        route: "/reversal",
        component: <Reversal />,
      },
      {
        name: "Data Array",
        key: "dataarray",
        route: "/dataarray",
        component: <DataArray />,
      },
      {
        name: "Reversal Mapping",
        key: "reversal-mapping",
        route: "/reversal-mapping",
        component: <BullishBearish />,
      },
    ],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Add User",
    key: "adduser",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/adduser",
    component: <AddUser />,
    role: "admin",
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Sign Out",
    key: "sign-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-out",
    component: <SignOut />,
  },
];

export default routes;
