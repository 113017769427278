import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableWithColumnFilters from "components/Tables/DataTableWithColumnFilters";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import failedMarketsZeroValueTableData from "layouts/tables/data/failedMarketsZeroValueTableData";

function FailedMarketsZeroValue() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [zeroValueData, setZeroValueData] = useState([]);
  const [timeframeValue, setTimeframeValue] = useState("daily");
  const [selectRegion, setSelectRegion] = useState(["All"]);
  // eslint-disable-next-line no-unused-vars
  const [selectSource, setSelectSource] = useState([]);

  const { columns } = failedMarketsZeroValueTableData();
  const [uniqueSourceValues, setUniqueSourceValues] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [fetchedZeroValueData, setFetchedZeroValueData] = useState([]);

  const getZeroValue = async () => {
    const result = await http({
      path: `/failedmarkets/zeroval`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleChangeTimeframe = (event, newTimeframe) => {
    setTimeframeValue(newTimeframe);
  };

  const handleChangeSelectRegion = (event, newSelectRegion) => {
    if (newSelectRegion === "All") setSelectRegion(["All"]);
    else setSelectRegion([newSelectRegion]);
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedFetchedZeroValueData = await getZeroValue();
          setFetchedZeroValueData(loadedFetchedZeroValueData);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [userData]);

  useEffect(() => {
    if (fetchedZeroValueData) {
      let filteredData = fetchedZeroValueData;
      if (!filteredData) return;
      switch (timeframeValue) {
        case "daily":
          filteredData = fetchedZeroValueData.find((item) => item.timeFrame === 1)?.results;
          break;
        case "weekly":
          filteredData = fetchedZeroValueData.find((item) => item.timeFrame === 2)?.results;
          break;
        case "monthly":
          filteredData = fetchedZeroValueData.find((item) => item.timeFrame === 3)?.results;
          break;
        case "quarterly":
          filteredData = fetchedZeroValueData.find((item) => item.timeFrame === 4)?.results;
          break;
        case "yearly":
          filteredData = fetchedZeroValueData.find((item) => item.timeFrame === 5)?.results;

          break;
        default:
          filteredData = fetchedZeroValueData.find((item) => item.timeFrame === 1)?.results;
          break;
      }

      if (selectRegion[0] !== "") {
        const regionFilter = selectRegion[0];
        let tempRegionData;
        switch (regionFilter) {
          case "All":
            tempRegionData = filteredData;
            break;
          case "Americas":
            tempRegionData = filteredData.filter((el) => ["US2", "US3"].includes(el.RunRegion));
            filteredData = tempRegionData;
            break;
          case "Economics":
            tempRegionData = filteredData.filter((el) => ["US4"].includes(el.RunRegion));
            filteredData = tempRegionData;
            break;
          case "Bonds":
            tempRegionData = filteredData.filter((el) => ["US1", "US5"].includes(el.RunRegion));
            filteredData = tempRegionData;
            break;
          default:
            tempRegionData = filteredData.filter((el) => el.RunRegion === regionFilter);
            filteredData = tempRegionData;
            break;
        }
      }

      setZeroValueData(filteredData);

      if (filteredData && filteredData.length > 0) {
        const uniqueSourceV = Array.from(
          new Set(filteredData.map((item) => item.Source).filter(Boolean))
        );
        setUniqueSourceValues(uniqueSourceV);
      }
    }
  }, [timeframeValue, selectRegion, selectSource, fetchedZeroValueData]);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={10}>
              <Card>
                <MDBox pt={2} px={3}>
                  <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    color="error"
                    textTransform="uppercase"
                  >
                    Total failed markets {timeframeValue} : {zeroValueData.length}
                  </MDTypography>
                </MDBox>
                <Grid item xs={6} m={3} mt={1} mb={0}>
                  <MDBox pt={1} px={1}>
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      Select the timeframe
                    </MDTypography>
                  </MDBox>
                  <ToggleButtonGroup
                    size="small"
                    color="info"
                    value={timeframeValue}
                    exclusive
                    onChange={handleChangeTimeframe}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="daily" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Daily
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="weekly" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Weekly
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="monthly" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Monthly
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="quarterly" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Quarterly
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="yearly" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Yearly
                      </MDTypography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6} m={3} mt={0} mb={0}>
                  <MDBox pt={1} px={1}>
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      Select the region
                    </MDTypography>
                  </MDBox>
                  <ToggleButtonGroup
                    size="small"
                    color="info"
                    value={selectRegion}
                    exclusive
                    onChange={handleChangeSelectRegion}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="All" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        All
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="Americas" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Americas
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="Asia" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Asia
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="Europe" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Europe
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="Economics" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Economics
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="Bonds" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Bonds
                      </MDTypography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6} m={3} mt={0} mb={0.5}>
                  <MDBox mb={2}>
                    <MDBox pt={1} px={1}>
                      <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                        Select the source
                      </MDTypography>
                    </MDBox>
                    <ToggleButtonGroup
                      size="small"
                      color="info"
                      value={selectSource}
                      exclusive
                      onChange={(event, newSelectSource) => setSelectSource(newSelectSource)}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="all" aria-label="centered">
                        <MDTypography variant="caption" color="text" fontWeight="regular">
                          All
                        </MDTypography>
                      </ToggleButton>
                      {uniqueSourceValues.map((source) => (
                        <ToggleButton value={source} aria-label="centered" key={source}>
                          <MDTypography variant="caption" color="text" fontWeight="regular">
                            {source}
                          </MDTypography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </MDBox>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {zeroValueData && zeroValueData.length > 0 ? (
        <MDBox my={3} mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Failed Markets Zero Value
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTableWithColumnFilters
                    table={{ columns, rows: zeroValueData }}
                    showTotalEntries
                    isSorted
                    noEndBorder
                    entriesPerPage
                    canSearch
                    arrayColumnsToSkipSorted={["Details", "Actions"]}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <p> No data available</p>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default FailedMarketsZeroValue;
